<template>
  <div class="articles-by-category">
    <PageTitle icon="mdi-folder" :main="category.name" sub="Categoria" />
    <ul>
      <li v-for="article in articles" :key="article.id">
        <!-- {{ article.name }} -->
        <ArticleItem :article="article" />
      </li>
    </ul>
    <div class="load-more">
      <v-btn
        v-if="loadMore"
        outlined
        color="primary"
        large
        @click="getArticles"
      >
        Carregar Mais Artigos
      </v-btn>
    </div>
  </div>
</template>

<script>

import { baseApiUrl } from "@/global";
import axios from "axios";

import PageTitle from "@/layouts/PageTitle.vue";
import ArticleItem from '@/components/ArticleItem.vue'



export default {
    name: 'ArticlesByCategory',
    components: { PageTitle, ArticleItem} , 
    data: function()  {
        return {
            category: {},
            articles: [],
            page: 1,
            loadMore: true
        }
    },
    methods: {
        getCategory() {
            const url = `${baseApiUrl}/categories/${this.category.id}`
            axios(url).then(res => this.category = res.data) 
        },
        getArticles() {
            const url = `${baseApiUrl}/categories/${this.category.id}/articles?page=${this.page}`
            axios(url).then(res => {
                this.articles = this.articles.concat(res.data)
                this.page++

                if(  res.data.length === 0) this.loadMore = false
            })                

        },

    },
    watch: {
        $route(to) {
            this.category.id = to.params.id
            this.articles = []
            this.page = 1
            this.loadMore = true

            this.getCategory()
            this.getArticles()
        }
    },    
    mounted() {
        this.category.id = this.$route.params.id;
        this.getCategory();
        this.getArticles();
    }

}
</script>

<style>
.articles-by-category ul {
  list-style-type: none;
  padding: 0px;
}

.articles-by-category .load-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}
</style>